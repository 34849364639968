import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import LogoImg from '../../assets/Logo.svg';
import Moscow from '../../assets/moscow.jpeg';

const Info = styled.div`
  padding: 32px;

  p {
    word-break: break-word;
    margin-bottom: 8px;
    font-size: 18px;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const Bold = styled.span`
  color: ${props => props.theme.palette.primary.main};
  font-weight: 700;
  font-size: 18px;
  
  @media screen and (max-width:600px) {
    font-size: 16px;
  },
`;

const StyledLink = styled.a`
  padding: 8px;
  color: black;
`;

const TermsPolicy = styled(Typography)`
  font-size: 13px !important;
`;

const Logo = styled.div`
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 16px;

  a {
    max-width: 240px;
    color: black;
    text-decoration: none;
    background: white;
    padding: 8px;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  img {
    display: block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
`;

const Contacts = () => {
  const mobileScreen = useMediaQuery('(max-width:768px)');

  return (
    <footer>
      <Grid container alignItems="stretch">
        <Grid item xs={12} sm={6} md={6}>
          <Info>
            <Logo>
              <NavLink to="/">
                <img src={LogoImg} alt="Центр юридической помощи" />
                <Typography sx={{ mb: '0 !important' }}>Центр юридической помощи</Typography>
              </NavLink>
            </Logo>
            <Typography>
              <Bold>Мы работаем:</Bold> с 9 до 21, пн-сб. Приём заявок 24/7
            </Typography>
            <Typography>
              <Bold>Телефон:</Bold>
              <StyledLink href="tel:tel:8-910-467-4000">8 910 467 4000</StyledLink>
            </Typography>
            <Typography>
              <Bold>Email:</Bold>
              <StyledLink href="mailto:groupbastion@yandex.ru">groupbastion@yandex.ru</StyledLink>
            </Typography>
            <TermsPolicy sx={{ mt: '48px' }}>
              <Link target="_blank" to="/terms">
                Положение
              </Link>
              &nbsp;и&nbsp;
              <Link target="_blank" to="/accept">
                согласие
              </Link>
              &nbsp;на обработку персональных данных
            </TermsPolicy>
          </Info>
        </Grid>
        {!mobileScreen && (
          <Grid item xs={12} sm={6} md={6}>
            <img
              src={Moscow}
              alt="Moscow"
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
              }}
            />
          </Grid>
        )}
      </Grid>
    </footer>
  );
};

export default Contacts;
