import Sogas from '../../assets/sogas.svg';
import Allianz from '../../assets/allianz-logo.svg';
import Alfa from '../../assets/alfa.svg';
import Ingosstrah from '../../assets/ingosstrah.png';
import Reso from '../../assets/reso.svg';
import Sber from '../../assets/sber.svg';
import Sber2 from '../../assets/sber2.svg';
import Vtb from '../../assets/vtb.svg';
import Raif from '../../assets/raif.svg';
import Sogas2 from '../../assets/sogas2.png';
import Aig from '../../assets/aig.png';
import Credendo from '../../assets/credendo.svg';
import Civ from '../../assets/civ.png';
import More from '../../assets/more.png';
import Tinkoff from '../../assets/tinkoff.svg';
import Russia from '../../assets/russia.png';
import Ros from '../../assets/ros.svg';
import Sovkom from '../../assets/sovkom.png';
import Trust from '../../assets/trust.svg';
import Open from '../../assets/open.png';
import Uni from '../../assets/uni.svg';

export const companies = [
  {
    title: 'АО «Согаз»',
    icon: Sogas,
  },
  {
    title: 'СК «Альянс»',
    icon: Allianz,
  },
  {
    title: '«АльфаСтрахование»',
    icon: Alfa,
  },
  {
    title: '«Ингосстрах»',
    icon: Ingosstrah,
  },
  {
    title: '«РЕСО-Гарантия»',
    icon: Reso,
  },
  {
    title: '«Сбербанк страхование жизни»',
    icon: Sber,
  },
  {
    title: 'СК «Сбербанк страхование»',
    icon: Sber2,
  },
  {
    title: 'СК «ВТБ Страхование»',
    icon: Vtb,
  },
  {
    title: 'СК «Райффайзен Лайф»',
    icon: Raif,
  },
  {
    title: '«СК СОГАЗ-ЖИЗНЬ»',
    icon: Sogas2,
  },
  {
    title: 'АО «АИГ»',
    icon: Aig,
  },
  {
    title: '«Кредендо — Ингосстрах Кредитное Страхование»',
    icon: Credendo,
  },
  {
    title: '«Страховая компания «СиВ Лайф»',
    icon: Civ,
  },
  {
    title: 'И другие',
    icon: More,
  },
];

export const banks = [
  {
    title: '«АльфаБанк»',
    icon: Alfa,
  },
  {
    title: '«Сбербанк Банк»',
    icon: Sber,
  },
  {
    title: '«ВТБ»',
    icon: Vtb,
  },
  {
    title: '«РайффайзенБанк»',
    icon: Raif,
  },
  {
    title: 'Тинькофф Банк',
    icon: Tinkoff,
  },
  {
    title: 'Банк Россия',
    icon: Russia,
  },
  {
    title: 'РосБанк',
    icon: Ros,
  },
  {
    title: 'СовкомБанк',
    icon: Sovkom,
  },
  {
    title: 'Банк Траст',
    icon: Trust,
  },
  {
    title: 'Банк Открытие',
    icon: Open,
  },
  {
    title: 'Юни Кредит Банк',
    icon: Uni,
  },
  {
    title: 'И другие',
    icon: More,
  },
];
