export const insuranceSteps = [
  {
    id: 1,
    title: 'Консультация',
    list: [
      'Оценка Вашего дела;',
      'Подготовка персонального плана действий;',
      'Оценка возможных рисков и препятствий',
    ],
  },
  {
    id: 2,
    title: 'Подготовка',
    list: [
      'Согласование плана действий;',
      'Подготовка комплекта документов;',
      'Досудебный порядок решения дела (подача претензий, проведение переговоров)',
    ],
  },
  {
    id: 3,
    title: 'Итог',
    list: [
      'Представление интересов клиента в суде;',
      'Участие в судебной экспертизе и иных мероприятиях;',
      'Получение положительного решения суда;',
      'Взыскание денег в рамках исполнительного производства',
    ],
  },
];
